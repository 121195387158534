import Vue from "vue";
import Router from "vue-router";
import store from "../store";

////////////////////////////////////

//Routes
const Home = () => import("./routes/home");
const Library = () => import("./routes/library");
const TendrilComponent = () => import("./routes/library/TendrilComponent.vue");
const createSchedule = () => import("./routes/create-schedule");
const editSchedule = () => import("./routes/schedules/edit-schedule");
const schedules = () => import("./routes/schedules");
const Bespoke = () => import("./routes/bespoke");
const Account = () => import("./routes/account");
const Payment = () => import("./routes/account/payment.vue");
const AboutUs = () => import("./routes/about-us");
const ContactUs = () => import("./routes/about-us/contact-us.vue");

// Admin

///////////////////////////////////

//User Authentication Routes / Admin
const User = () => import("./routes/user/user.vue");
const Login = () => import("./routes/user/login.vue");
const Signup = () => import("./routes/user/signup.vue");
const Forgot = () => import("./routes/user/forgot.vue");
const Reset = () => import("./routes/user/reset.vue");

///////////////////////////////////

//Use the router
Vue.use(Router);

///////////////////////////////////

var array = [];

///////////////////////////////////
array.push({
    path: "/",
    redirect: "/home",
    component: Home,
});

// Home
//////////////////////////////////////

array.push({
    name: "home",
    path: "/home",

    component: Home,
});

// About Us
//////////////////////////////////////

array.push({
    name: "About Us",
    path: "/about-us",

    component: AboutUs,
});

// Contact Us
//////////////////////////////////////

array.push({
    name: "contact-us",
    path: "/contact-us",

    component: ContactUs,
});

// Schedules
//////////////////////////////////////
array.push({
    name: "schedule",
    path: "/schedules",

    component: schedules,
});

//Create Schedule
array.push({
    name: "create-schedule",
    path: "/create-schedule",
    meta: {
        requireUser: true,
        requireLicense: true,
    },
    component: createSchedule,
});

array.push({
    name: "edit-schedule",
    path: "/edit-schedule/:id",
    meta: {
        requireUser: true,
        requireLicense: true,
    },
    component: editSchedule,
});

// Library
//////////////////////////////////////
array.push({
    name: "library",
    path: "/library",

    component: Library,
});

array.push({
    name: "tendril-component",
    path: "/library/:id",
    meta: {
        denyUser: true,
        hideFooter: true,
        hideHeader: true,
    },
    component: TendrilComponent,
});

// Bespoke
//////////////////////////////////////
array.push({
    name: "bespoke",
    path: "/bespoke",

    component: Bespoke,
});

// Account
//////////////////////////////////////
array.push({
    name: "account",
    path: "/account",
    meta: {
        requireUser: true,
    },
    component: Account,
});

array.push({
    name: "payment",
    path: "/account/payment",

    component: Payment,
});

// Auth Routes
//////////////////////////////////////

array.push({
    name: "auth",
    path: "/auth",
    meta: {
        title: "Start",
        denyUser: true,
    },

    component: User,

    children: [
        {
            name: "auth.login",
            path: "login",
            meta: {
                title: "Login",
                denyUser: true,
                hideFooter: true,
            },
            component: Login,
        },
        {
            name: "auth.signup",
            path: "signup",
            meta: {
                title: "Sign Up",
                denyUser: true,
                hideFooter: true,
            },
            component: Signup,
        },
        {
            name: "auth.forgot",
            path: "forgot",
            meta: {
                title: "Forgot Password",
                denyUser: true,
                hideFooter: true,
            },
            component: Forgot,
        },
        {
            name: "auth.reset",
            path: "reset",
            meta: {
                title: "Reset Your Password",
                denyUser: true,
                hideFooter: true,
            },
            component: Reset,
            props: (route) => ({
                token: route.query.token,
            }),
        },
    ],
});

///////////////////////////////////

///////////////////////////////////

///////////////////////////////////

///////////////////////////////////

var router = new Router({
    mode: "history",
    routes: array,

    scrollBehavior(to, from, savedPosition) {
        //Keep track of where the user was scrolled
        //if they hit the back button
        var pos = 0;
        // Scroll to the top
        if (savedPosition) {
            pos = savedPosition.y;
        }

        setTimeout(function () {
            document.querySelector(".v-main__wrap").scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }, 300);

        // document.body.scrollTop = document.documentElement.scrollTop = pos;
    },
});

///////////////////////////////////

router.beforeEach(async (to, from, next) => {
    if (!to || !to.meta || !to.name) {
        console.log("NO ROUTE");
        return next("/auth/login");
    }

    if (to.meta) {
        //Get the user session from fluro
        let user = store.getters["fluro/user"];

        //If the route only allows logged in users
        if (to.meta.requireUser && !user) return next("/auth/login");
        if (to.meta.requireLicense && !user?.isPremium) {
            //If content requires a license (Premium content).
            //If user doesn't have premium access, Redirect
            return next("/account/payment");
        }
    }

    return next();
});

// router.afterEach(async (to, from) => {
//   console.warn("AFTER ROUTE?");
// });

// router.beforeResolve((to, from, next) => {
//   console.warn("Route loaded?");

//   return next();
// });

///////////////////////////////////

export default router;

///////////////////////////////////
