import Vue from "vue";
import Vuex from "vuex";
// import createPersistedState from 'vuex-persistedstate'

////////////////////////////////////////

//Modules
import ui from "./modules/ui";
import assets from "./modules/assets";

//Initiate Vuex
Vue.use(Vuex);

////////////////////////////////////////

//Create the store
const store = new Vuex.Store({
   //Save state to local storage
   // plugins: [createPersistedState()],
   modules: {
      ui,
      assets,
   },
   ///////////////////////////////////////

   state: {
      email: "", //For when someone is logging in/out remember it in forms
      purchases: [],
      libraryCollection: {},
      user: {},
      headerCache: 0,
      orientation: "portrait",
   },

   mutations: {
      generalSet(state, payload) {
         state[payload.key] = payload.data;
      },

      email(state, payload) {
         state.email = payload;
      },
   },
   getters: {
      email(state, getters) {
         return state.email;
      },
   },
});

///////////////////////////////////////
//Add to the root
Vue.$store = store;

////////////////////////////////////////

//Export it
export default store;
