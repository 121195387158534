<template>
    <div class="text-center">
        <v-dialog v-model="dialog" width="80%" content-class="br-10 overflow-auto">
            <template v-slot:activator="{ on, attrs }">
                <div class="p-relative" dark v-bind="attrs" v-on="on"></div>
            </template>

            <v-row no-gutters class="d-flex align-center justify-center pricing-dialog">
                <v-col cols="12" class="mt-10 mb-14 text-center">
                    <h3 class="">Subscriptions</h3>
                </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-row no-gutters class="d-flex align-center justify-center mt-n8">
                <v-col cols="12" class="d-flex flex-column align-center pb-5">
                    <v-card class="br-10 pb-6 fill-height d-flex flex-column" style="width: 80%">
                        <!-- <v-card-title class="d-flex align-center justify-center"> Red Sparrow Member </v-card-title> -->
                        <v-card-text>
                            <v-row class="ma-0">
                                <v-col cols="6" class="py-1 py-sm-2 text-subtitle-1 d-flex flex-column align-center">
                                    <v-icon class="pa-1" :size="$vuetify.breakpoint.smAndUp ? 60 : 40">mdi-calendar-month</v-icon>
                                    <div class="text-center">Monthly Subscription</div>
                                    <h4 v-if="$vuetify.breakpoint.xsOnly" class="pt-2 text-uppercase">
                                        {{ monthlySubscriptionPrice }}
                                    </h4>
                                    <h3 v-else class="text-uppercase">{{ monthlySubscriptionPrice }}</h3>
                                </v-col>
                                <v-col cols="6" class="py-1 py-sm-2 text-subtitle-1 d-flex flex-column align-center">
                                    <v-icon class="pa-1" :size="$vuetify.breakpoint.smAndUp ? 60 : 40">mdi-calendar-multiple</v-icon>
                                    <div class="text-center">Annual Subscription</div>
                                    <h4 v-if="$vuetify.breakpoint.xsOnly" class="pt-2 text-uppercase">
                                        {{ annualSubscriptionPrice }}
                                    </h4>
                                    <h3 v-else class="text-uppercase">{{ annualSubscriptionPrice }}</h3>
                                </v-col>
                            </v-row>

                            <v-row class="ma-0">
                                <v-spacer />
                                <v-col cols="12" sm="8" class="py-3 px-0 text-subtitle-1 d-flex flex-column justify-center">
                                    <div class="py-1 py-sm-3 text-subtitle-1 d-flex align-center">
                                        <v-icon class="mx-3" color="var(--primary)">fa-solid fa-check</v-icon>
                                        <div>Access to our full library of Interactive Social Stories</div>
                                    </div>

                                    <div class="py-1 py-sm-3 text-subtitle-1 d-flex align-center">
                                        <v-icon class="mx-3" color="var(--primary)">fa-solid fa-check</v-icon>
                                        <div>Access to the full version of Schedule Builder</div>
                                    </div>

                                    <div class="py-1 py-sm-3 text-subtitle-1 d-flex align-center">
                                        <v-icon class="mx-3" color="var(--primary)">fa-solid fa-check</v-icon>
                                        <div>Access to all images in the Red Sparrow Library for the Schedule Builder</div>
                                    </div>
                                </v-col>
                                <v-spacer />
                            </v-row>
                            <v-row class="ma-0 py-0 py-sm-4 px-0 px-sm-8">
                                <v-col
                                    cols="12"
                                    sm="3"
                                    class="py-0 py-sm-6 d-flex"
                                    :class="$vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'"
                                >
                                    <v-img :src="assets.logo.NDIS" height="auto" max-width="8vw"></v-img>
                                </v-col>
                                <v-col cols="12" sm="9" class="d-flex align-center">
                                    <div style="">
                                        NDIS participants can claim Core Supports funding under Consumables.
                                        <br />
                                        ITEM: Low-cost communication and Cognitive Support.
                                        <br />
                                        Code: 03_222100911_0124_1_1
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-spacer></v-spacer>
                        <v-card-actions class="d-flex align-center justify-center py-2">
                            <v-btn
                                dark
                                height="60"
                                class="px-8 ux-button"
                                @click="
                                    $router.push('/account/payment');
                                    dialog = false;
                                "
                            >
                                <span class="text-h6 font-weight-bold">Get Started</span>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-spacer />
            </v-row>
        </v-dialog>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    data() {
        return {
            dialog: false,
            monthlySubscription: null,
            annualSubscription: null,
        };
    },
    components: {},

    props: ["item"],
    computed: {
        ...mapState(["assets"]),
        monthlySubscriptionPrice() {
            return this.monthlySubscription?.amount
                ? `$${this.monthlySubscription?.amount / 100} ${this.monthlySubscription?.currency}`
                : "$12 AUD";
        },
        annualSubscriptionPrice() {
            return this.annualSubscription?.amount
                ? `$${this.annualSubscription?.amount / 100} ${this.annualSubscription?.currency}`
                : "$84 AUD";
        },
    },
    methods: {
        getImage(image) {
            const url = `${this.$fluro.asset.imageUrl(image)}&noRedirect=true`;
            return url;
        },
        open() {
            this.dialog = true;
        },
        async getProducts() {
            const query = {
                _type: "product",
                status: "active",
                _id: {
                    $in: ["641a756e82c6a4039eb70811", "641a75c582c6a4039eb7081b"],
                },
            };

            return await this.$fluro.api
                .post("/content/_query", query, {
                    select: "amount currenecy",
                })
                .then(({ data }) => {
                    return data;
                })
                .catch((err) => console.log(err));
        },
    },

    async mounted() {
        let products = await this.getProducts();
        // console.warn("products:", products);
        this.monthlySubscription = products.find((item) => item._id == "641a75c582c6a4039eb7081b");
        this.annualSubscription = products.find((item) => item._id == "641a756e82c6a4039eb70811");
    },
};
</script>

<style lang="scss">
.br-10 {
    border-radius: 10px;
}
.shadow {
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.25) 5px 4px 5px 0px;
}
.pricing-dialog {
    // background-color: var(--v-secondary-base);
    background: #bee3db;
    color: #2a3634;
}
</style>
