// Import the `getField` getter and the `updateField`
// mutation function from the `vuex-map-fields` module.

///////////////////////////////////

const state = {
    home: {
        bespoke: "/assets/home/children-burst.jpg",
        cover: "/assets/home/child-drawings.jpg",
        coverSmall: "/assets/home/headerSmaller.png",
        iPhoneBrushTeeth: "/assets/home/iPhone14_brush_teeth.svg",
        iPhoneShoeLaces: "/assets/home/iPhone14_shoelaces.svg",
        // iPhoneSchool: "/assets/home/iPhone14_ready_for_school.svg",
        iPadBrushTeeth: "/assets/home/iPad_brush_teeth.svg",
        iPadShoeLaces: "/assets/home/iPad_shoelaces.svg",
        iPadSchool: "/assets/home/iPad_ready_for_school.svg",
        schedules: "/assets/home/schedules.jpg",
        builder: "/assets/home/builder.jpg",
        iPadBoy: "/assets/home/children-boy.jpg",
        scheduleActive: "/assets/icons/menu/schedule-active.png",
        schdule: "/assets/icons/menu/schedule.png",
    },
    createSchedule: {
        viewAllSchedulesIcon: "/assets/icons/create-schedule/viewSchedule.svg",
        createScheduleIcon: "/assets/icons/create-schedule/createSchedule.svg",
        editScheduleIcon: "/assets/icons/create-schedule/editSchedule.svg",
        uploadAPhotoIcon: "/assets/icons/create-schedule/scheduleImages.svg",
    },
    icons: {
        libraryActive: "/assets/icons/menu/library-active.png",
        library: "/assets/icons/menu/library.png",
        scheduleActive: "/assets/icons/menu/schedule-active.png",
        schedule: "/assets/icons/menu/schedule.png",
        createSchedule: "/assets/icons/menu/create-schedule.png",
        createScheduleActive: "/assets/icons/menu/create-schedule-active.png",
        bespokeActive: "/assets/icons/menu/bespoke-active.png",
        bespoke: "/assets/icons/menu/bespoke.png",
        empower: "/assets/icons/about-us/empower icon.png",
        ourvalues: "/assets/icons/about-us/Our valuesicon.png",
        support: "/assets/icons/about-us/support.png",
    },
    library: {
        libraryTitle1: "/assets/library/library-title-1.svg",
        libraryTitle2: "/assets/library/library-title-2.svg",
        libraryStackAlt: "/assets/library/book-stack-alt.vue",
    },
    schedules: {
        together: "/assets/schedule/together.mp3",
        celebrates: "/assets/schedule/celebrates.mp3",
        woohooo: "/assets/schedule/woohooo.mp3",
        schedule: "/assets/icons/schedules/schedules.svg",
        scheduleIcon: "/assets/icons/schedules/scheduleIcon.svg",
        chime: "/assets/schedule/chime.mp3",
        clear: "/assets/schedule/clear-tone.mp3",
        experiment: "/assets/schedule/experiment.mp3",
        bright: "/assets/schedule/fromhere.mp3",
        overdrive: "/assets/schedule/overdrive.mp3",
        imageUpload: "/assets/icons/schedules/imageUpload.svg",
        imgFile: "/assets/icons/schedules/imgFile.svg",
        photoFile: "/assets/icons/schedules/photoFile.svg",
        photoUpload: "/assets/icons/schedules/photoUpload.svg",
        fileLoad: "/assets/icons/schedules/file-load.svg",
        pencil: "/assets/icons/schedules/pencil.svg",
    },
    logo: {
        logoNew: "/assets/logo/new-logo.png",
        logoFull: "/assets/logo/logo-full.svg",
        logoPng: "/assets/logo/logo-png.png",
        // NDIS: "/assets/logo/NDIS.png",
        NDIS: "/assets/logo/NDIS_2020.jpg",
    },
    user: {
        userCircle: "/assets/icons/user/user-circle.png",
        userCircleInactive: "/assets/icons/user/user-circle-inactive.png",
        userCircleActive: "/assets/icons/user/user-circle-active.png",
    },
    bespoke: {
        birds: "/assets/icons/bespoke/birdsTransitions.svg",
        email: "/assets/icons/bespoke/email.svg",
        threeIcons: "/assets/icons/bespoke/threeIcons.svg",
        nippersIcon: "/assets/icons/bespoke/nippers_icon.svg",
        virtualTourIcon: "/assets/icons/bespoke/360_tour_icon.png",
        policeIcon: "/assets/icons/bespoke/police_icon.svg",
        bookCoverPolice: "/assets/book-covers/RedSparrowBooks_BookCovers_Icons_FINAL_Artboard 5_PoliceHelp.jpg",
        bookHairDresser: "/assets/book-covers/RedSparrowBooks_BookCovers_Icons_FINAL_Artboard 4_Hairdresser.jpg",
        bookNippers: "/assets/book-covers/RedSparrowBooks_BookCovers_Icons_WNippers_Blue.jpg",
        bookEasternRanges: "/assets/book-covers/EasternRanges.jpg",
    },
    aboutUs: {
        titleImage: "/assets/about-us/about-us.svg",
        authors: "/assets/about-us/melissa&jo.jpg",
        aboutUs: "/assets/about-us/aboutUsWhite.png",
        aboutUsFull: "/assets/about-us/aboutUsFull.png",
        aboutUsFill: "/assets/about-us/aboutUsFill.png",
        aboutUsMob: "/assets/about-us/aboutUsMob.png",
        aboutUsMob2: "/assets/about-us/aboutUsMob2.png",
        aboutUsMob3: "/assets/about-us/aboutUsMob3.png",
    },
};

///////////////////////////////////

const mutations = {};

///////////////////////////////////

const getters = {};

///////////////////////////////////

const actions = {};

///////////////////////////////////

export default {
    state,
    mutations,
    getters,
    actions,
};
