import Vue from "vue";
import Vuetify from "vuetify/lib";
import "font-awesome/css/font-awesome.min.css"; // Ensure you are using css-loader
import BookStack from "@/svgs/BookStack.vue";
Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {
      BookStack: {
        component: BookStack,
      },
    },
    iconfont: "fa4",
  },
  theme: {
    options: {
      customProperties: true,
    },
    dark: false,
    themes: {
      light: {
        primary: "#3DB2B9",
        secondary: "#C6451B",
        heading: "#6D6E71",
        grey: "#EEEEEE",
        "dark-grey": "#CCCCCC",
        dark: "#333333",
        background: "#FFFFFF",
        light: "#FFFFFF",
        success: "#46c93a",
        warning: "#ffba00",
        error: "#ff4757",
        library: "#eeeeee",
        schedules: "#879AE2",
        transitions: "#FF7575",
      },
      dark: {
        primary: "#FFF200",
        secondary: "#C6451B",
        heading: "#FFFFFF",
        grey: "#444444",
        dark: "#FFFFFF",
        background: "#121212",
        light: "#363636",
        success: "#46c93a",
        warning: "#ffba00",
        error: "#ff4757",
        library: "#eeeeee",
        schedules: "#879AE2",
        transitions: "#FF7575",
      },
    },
  },
});
