<template>
    <div>
        <div v-if="$vuetify.breakpoint.smAndDown" class="RSheader align-center d-flex justify-space-between px-4 py-2">
            <!-- <v-img :src="headerLogo" max-width="80" height="80" contain eager @click.native="$router.push('/home')"></v-img> -->
            <a href="/"><img class="logo-icon" :src="headerLogo" /></a>
            <div class="pa-1" @click.stop="drawer = !drawer">
                <v-icon class="mr-4" large>fa-bars</v-icon>
            </div>
            <v-navigation-drawer v-model="drawer" absolute right temporary flat class="d-flex flex-column">
                <v-img class="mx-auto my-4" :src="assets.logo.logoFull" max-width="80" max-height="80" contain eager></v-img>
                <v-divider></v-divider>
                <v-list nav>
                    <v-list-item-group v-model="group" active-class="active-list-item">
                        <v-list-item v-for="(item, index) in config.menu" :key="index" class="my-4" @click="goTo(item)">
                            <v-icon v-if="item.icon" size="30" :color="activeColour(index)" class="pl-1 mr-7">{{ item.icon }}</v-icon>
                            <v-img
                                v-if="item.image && group != index"
                                :src="item.image"
                                :max-width="item.width"
                                :max-height="item.height"
                                contain
                                class="mr-4"
                            ></v-img>
                            <v-img
                                v-if="item.image && group == index"
                                :src="item.activeImage"
                                :max-width="item.width"
                                :max-height="item.height"
                                contain
                                class="mr-4"
                            ></v-img>
                            <span class="mt-1">
                                {{ item.title }}
                            </span>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item v-if="$app.user" @click="signOut()" class="my-6">
                            <v-icon class="pl-1 mr-7" size="30">fa-sign-in</v-icon>
                            <span>Sign Out</span>
                        </v-list-item>
                        <v-list-item v-else @click="signIn()" class="my-6">
                            <v-icon class="pl-1 mr-7" size="30">fa-sign-out</v-icon>
                            <span>Sign In</span>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-navigation-drawer>
        </div>
        <div v-else class="RSheader d-flex align-center px-6 py-4">
            <!--MAIN HEADER LOGO HERE!!!-->
            <!-- <img
             :src="this.assets.logo.logoNew"
             class="mr-10"
             width="384"
             height="80"
             contain
             eager
             @click="$router.push('/home')" /> -->
            <a href="/"><img class="logo-icon" :src="headerLogo" /></a>
            <div class="header-link-large" v-for="(link, index) in config.menu" @click="goTo(link)" :key="index">
                <div class="font-weight-medium">{{ link.title }}</div>
            </div>
            <v-spacer></v-spacer>
            <template v-if="$app.user">
                <small class="mx-5 d-flex align-center justify-center" v-if="$app.user.isPremium">
                    <v-icon class="mr-2" color="success">mdi-crown</v-icon>
                    <div class="d-flex flex-column">
                        <div>Red Sparrow Member</div>
                        <div v-if="$app.user.freeTrialUser" class="text--secondary text-caption">Free Trial</div>
                    </div>
                </small>
                <v-btn v-else @click="payment()" elevation="0">
                    <p class="mb-0">Become a member</p>
                    <v-icon size="20" class="ml-2" color="white">mdi-crown</v-icon>
                </v-btn>
            </template>
            <v-btn v-if="$app.user" class="header-button outline" elevation="0" @click="signOut()" depressed>Sign Out</v-btn>
            <div v-else class="d-flex align-center justify-center">
                <!-- <v-btn class="header-button mr-5" @click="payment()" elevation="0"> -->
                <v-btn class="header-button mr-5" @click="trial()" elevation="0">
                    <p class="mb-0">Try for free</p>
                    <!-- <v-icon size="20" class="ml-2" color="white">mdi-crown</v-icon> -->
                </v-btn>
                <v-btn class="outline header-button" outlined elevation="0" @click="signIn()" depressed>Sign In</v-btn>
            </div>
        </div>
        <pricing-dialog ref="pricing-dialog" />
    </div>
</template>
<script>
import { mapState } from "vuex";
import PricingDialog from "@/components/layout/pricing-dialog.vue";

export default {
    name: "RSheader",
    components: {
        PricingDialog,
    },
    data() {
        return {
            drawer: false,
            group: null,
        };
    },

    watch: {
        group() {
            console.log(this.group);
            this.drawer = false;
        },
    },
    computed: {
        ...mapState(["assets"]),
        config() {
            return {
                menu: [
                    {
                        title: "Home",
                        icon: "fa-home",
                        path: "/home",
                    },
                    {
                        title: "Social Stories",
                        image: this.assets.icons.library,
                        activeImage: this.assets.icons.libraryActive,
                        width: "45",
                        height: "30",
                        path: "/library",
                    },
                    {
                        title: "Visual Schedules",
                        image: this.assets.icons.schedule,
                        activeImage: this.assets.icons.scheduleActive,
                        width: "45",
                        height: "30",
                        path: "/schedules",
                    },
                    {
                        title: "Create Schedules",
                        image: this.assets.icons.createSchedule,
                        activeImage: this.assets.icons.createScheduleActive,
                        width: "45",
                        height: "30",
                        path: "/create-schedule",
                    },
                    {
                        title: "Custom",
                        image: this.assets.icons.bespoke,
                        activeImage: this.assets.icons.bespokeActive,
                        width: "45",
                        height: "30",
                        path: "/bespoke",
                    },
                    {
                        title: "Subscriptions",
                        // title: "Pricing",
                        image: this.assets.user.userCircleInactive,
                        activeImage: this.assets.user.userCircleActive,
                        width: "45",
                        height: "30",
                    },
                    // {
                    //    title: "Membership",
                    //    icon: "fa-user-circle",
                    //    path: "/account",
                    // },
                ],
            };
        },
        headerLogo() {
            let logo = `${this.assets.logo.logoNew}`;
            return logo;
        },
    },
    methods: {
        activeColour(index) {
            if (this.group == index) {
                return "white";
            }

            return "";
        },

        listClass(item, index) {
            return `${item.image ? "pl-0" : ""} ${this.group == index ? `active-list-item-${index}` : ""}`;
        },

        goTo(item) {
            if (item?.title == "Subscriptions") {
                this.$refs["pricing-dialog"]?.open();
            } else {
                this.$router.replace(item.path);
            }
        },

        payment() {
            this.$router.push({ name: "payment" });
        },

        trial() {
            this.$router.push({ name: "auth.signup" });
        },

        signIn() {
            this.$router.push({ name: "auth.login" });
        },
        signOut() {
            this.$fluro.auth.logout();
            this.$router.push({ name: "auth.login" });
        },
    },
};
</script>
<style lang="scss">
.RSheader {
    background-color: white;
    width: 100vw;
    box-shadow: 0 0 10px 2px rgba(#000, 0.1);
    z-index: 5;
}

.active-list-item {
    background-color: var(--v-background-base) !important;
}

.header-link-large {
    cursor: pointer;
    color: #878787;
    margin-right: 2em;
    flex: none;
}

.header-button {
    border-radius: 3px !important;
}

.logo-icon {
    max-height: 36px;
    display: inline-block;
    margin: 1em 2em 1em 0;
}
</style>
