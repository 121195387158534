<template>
    <div>
        <randomquote />
        <div class="footer">
            <div class="constraint">
                <!-- <div class="logo">
                  <a href="/home">
               <v-img
                  :to="{ name: 'home' }"
                  :src="assets.logo.logoFull"
                  max-width="100"
                  height="100"
                  contain
                  eager
               ></v-img
            ></a>
            </div> -->

                <ul class="menu">
                    <li :key="item.title" v-for="item in icons">
                        <router-link :to="item.path">
                            {{ item.title }}
                        </router-link>
                    </li>
                </ul>
                <ul class="social">
                    <li>
                        <a href="https://www.instagram.com/redsparrowforautism" target="_blank">
                            <v-icon size="larger" class="mx-5 my-2">mdi-instagram</v-icon>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/redsparrowforautism" target="_blank">
                            <v-icon size="larger" class="mx-5 my-2">mdi-facebook</v-icon>
                        </a>
                    </li>
                    <li>
                        <a href="mailto:redsparrowforautism@gmail.com">
                            <v-icon size="larger" class="mx-5 my-2">mdi-email-outline</v-icon></a
                        >
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
export default {
    name: "RSheader",
    data() {
        return {
            drawer: false,
            group: null,
        };
    },

    watch: {
        group() {
            console.log(this.group);
            this.drawer = false;
        },
    },
    computed: {
        ...mapState(["assets"]),
        icons() {
            return [
                {
                    title: "Home",
                    icon: "fa-home",
                    path: "/home",
                },
                {
                    title: "About Us",
                    icon: "fa-home",
                    path: "/about-us",
                },
                {
                    title: "Contact Us",
                    image: this.assets.icons.bespoke,
                    activeImage: this.assets.icons.bespokeActive,
                    width: "45",
                    height: "30",
                    path: "/contact-us",
                },
            ];
        },
        user() {
            let user = this.$fluro.auth.getCurrentUser();
            return user ? true : false;
        },
    },
    methods: {
        activeColour(index) {
            if (this.group == index) {
                return "white";
            }

            return "";
        },

        listClass(item, index) {
            return `${item.image ? "pl-0" : ""} ${this.group == index ? `active-list-item-${index}` : ""}`;
        },

        goTo(item) {
            this.$router.replace(item.path);
        },

        signIn() {
            this.$router.push({ name: "auth.login" });
        },
        signOut() {
            this.$fluro.auth.logout();
            this.$router.push({ name: "auth.login" });
        },
    },
};
</script>
<style lang="scss">
.footer {
    padding: 2em;
    // background-color: #333;
    color: #777;
    font-size: 1.2em;

    a {
        opacity: 0.9;
        cursor: pointer;
        color: inherit !important;

        .v-icon {
            font-size: 1.5em !important;
            color: inherit !important;
        }

        &:hover {
            opacity: 1;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        display: block;

        li {
            margin: 0;
            padding: 0;
            display: inline-block;
        }
    }

    .menu li {
        margin-right: 1em;
    }

    .constraint {
        display: flex;
        gap: 1em;
        align-items: center;

        .menu {
            flex: 1;
        }

        .social,
        .logo {
            flex: none;
        }
    }

    @media (max-width: 640px) {
        padding: 15px;

        .logo {
            display: none;
        }

        ul li {
            margin: 0;
        }

        .menu li {
            display: block;
        }
    }
}
</style>
