import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vuetify from "./plugins/vuetify";
import AppModule from "./store/modules/app";
import "neuicons";
///////////////////////////////////////

import FluroVue from "fluro-vue";

Vue.use(FluroVue, { store });
Vue.use(AppModule);
/////////////////////////////////////////////////////

import Meta from "vue-meta";

Vue.use(Meta, {
    keyName: "meta",
    attribute: "data-vue-meta",
    ssrAttribute: "data-vue-meta-server-rendered",
    tagIDKeyName: "vmid",
    refreshOnceOnNavigation: true,
});

/////////////////////////////////////////////////////

//Listen for when the user session changes
var fluro = Vue.prototype.$fluro;
fluro.auth.addEventListener("change", userUpdated);

/////////////////////////////////////////////////////

//Keep track of whether the user is logged in
var previousLoggedInUser;
var vm;

/////////////////////////////////////////////////////

/**
 *
 * @param {*} user
 * @returns Returns user object - Checks if user has valid Purchase & License, if true, adds isPremium key on the user object.
 */
async function checkPremium(user) {
    //Mongo params to pass into fluro
    console.log("USER PERSONA", user.persona);
    const query = {
        _type: "purchase",
        status: "active",
        // managedPurchaser: user.persona,
        managedLicense: {
            $in: user.persona,
        },
        expiryDate: {
            $gte: new Date(), //What about purchase that do not have an expiry? Although probably safer to have this...
        },
        product: {
            //Purchase product must be one of the following,
            //7-day Free Trial: 64262004c18abe7229db9f4c
            //Annual Subscription: 641a756e82c6a4039eb70811
            //Monthly Subscription: 641a75c582c6a4039eb7081b
            $in: ["64262004c18abe7229db9f4c", "641a756e82c6a4039eb70811", "641a75c582c6a4039eb7081b"],
        },
    };

    const purchases = await fluro.api
        .post("/content/_query", query, {
            params: {
                noCache: true,
            },
        })
        .then(({ data }) => {
            return data;
        })
        .catch((err) => console.log(err));

    // console.log("ManagedOwner's PURCHASES (Query Result):", purchases);
    //Force refresh the RSHeader which displays membership status
    store.commit("generalSet", {
        key: "headerCache",
        data: store.state.headerCache + 1,
    });

    if (purchases.length) {
        // console.log("Purchases", purchases);
        //ARRAY OF VALID LICENSES
        const validLicenses = purchases.filter((purchase) => {
            const hasLicense = purchase?.license?.length > 0 || purchase?.managedLicense?.length > 0;

            const expiryDate = new Date(purchase?.expiryDate);
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            const notExpired = purchase?.expires == false || (purchase?.expires == true && today < expiryDate);
            return hasLicense && notExpired;
        });
        if (validLicenses) {
            console.log("User has license!", validLicenses);
            user.isPremium = true;

            if (validLicenses.every((license) => license.product === "64262004c18abe7229db9f4c")) {
                user.freeTrialUser = true;
            }
            store.commit("generalSet", { key: "purchases", data: purchases });
        }
    } else {
        user.isPremium = false;
    }
    return user;
}
Vue.prototype.$app.checkPremium = checkPremium;
/////////////////////////////////////////////////////
async function getProducts() {
    const query = {
        _type: "product",
        status: "active",
        _id: {
            $in: ["64262004c18abe7229db9f4c", "641a756e82c6a4039eb70811", "641a75c582c6a4039eb7081b"],
        },
    };

    return await fluro.api
        .post("/content/_query", query, {
            select: "amount currenecy",
        })
        .then(({ data }) => {
            Vue.prototype.$app.monthlySubscription = data.find((item) => item._id == "641a75c582c6a4039eb7081b");
            Vue.prototype.$app.annualSubscription = data.find((item) => item._id == "641a756e82c6a4039eb70811");
            Vue.prototype.$app.freeTrial = data.find((item) => item._id == "64262004c18abe7229db9f4c");
            return data;
        })
        .catch((err) => console.log(err));
}
/////////////////////////////////////////////////////

//Everytime the user changes
async function userUpdated(user) {
    //Check if the user was logged in before this change
    var wasLoggedInAs = previousLoggedInUser;

    if (user) {
        await getProducts();
        //We are authenticated as a user
        //Checks if they are premium
        // console.log(user);
        const checkedUser = await checkPremium(user);
        // console.log("checkedUser:", checkedUser);
        Vue.prototype.$app.user = checkedUser;
        previousLoggedInUser = checkedUser;

        console.log("USER", Vue.prototype.$app.user);
    } else {
        // console.log("NO USER");
        //We are not logged in
        previousLoggedInUser = null;
        Vue.prototype.$app.user = null;
        if (fluro.applicationToken) {
            //We are authenticated as a static application
        } else {
            //We are not authenticated at all
        }

        //If we were logged in before
        //and now we aren't
        if (wasLoggedInAs) {
            // vm.$toasted.show(`See you next time ${wasLoggedInAs.firstName}!`, {
            //     // icon:'check'
            //     duration: 3500,
            //     type: 'success',
            // })

            //Redirect to the home page
            console.log("Go to login");
            // router.push({ name: 'auth.login' });
        }

        router.push({ name: "auth.login" });
    }
}

///////////////////////////////////////

let user = store.getters["fluro/user"];

//Sync new permissions on reload
if (user) {
    //could probably get rid of sync user here
    fluro.auth.sync();
    store.commit("generalSet", { key: "user", data: user });
}

console.log(user, "user");

/////////////////////////////////////////////////////

//Listen for general Fluro errors and print them to screen
//then you can thow an error from anywhere like this this.$fluro.error(err)
fluro.addEventListener("error", function (message) {
    console.log("Error", message);
});

/////////////////////////////////////////////////////

//Use Fluro as an event bus for notifications
//this.$fluro.notication('some message', options) it means we can swap
//out the component we use to render the notifications easily
fluro.addEventListener("notification", function (details) {
    console.log("Notification event", details);
});

///////////////////////////////////////

import Randomquote from "@/components/randomquote.vue";

Vue.component("randomquote", Randomquote);

//Include filters globally
Vue.prototype.$filters = Vue.options.filters;

Vue.directive("blur", {
    inserted: function (el) {
        el.onfocus = (ev) => ev.target.blur();
    },
});

Vue.filter("capitalize", function (value) {
    var string = value;
    return string.charAt(0).toUpperCase() + string.slice(1);
});
///////////////////////////////////////

// EVENT BUS
export const EventBus = new Vue();

// import '@/styles/styles.scss'

Vue.config.productionTip = false;

vm = new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
