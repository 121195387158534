<template>
    <v-app id="app">
        <div class="safe-area-padding-top light zindex-10"></div>
        <RSheader v-if="!hideHeader" :key="headerCache" />
        <div class="navigation-container" :class="{ desktop: $vuetify.breakpoint.mdAndUp }">
            <v-main class="flex-grow-1 flex-shrink-1" :class="background">
                <TransitionGroup name="slide-y-transition" mode="out-in" appear :key="$route.path">
                    <router-view class="" :key="`${$route.path}-router`" @hook:mounted="routeMounted" />
                    <RSfooter v-if="loading == false && !hideFooter" :key="`${$route.path}-footer`" />
                </TransitionGroup>
            </v-main>
        </div>
        <transition name="fade-transition" mode="out-in" appear>
            <v-overlay color="light" opacity="0.85" class="loading-overlay zindex-max" v-if="loading">
                <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
            </v-overlay>
        </transition>
        <div v-if="$vuetify.breakpoint.mdAndUp" class="safe-area-padding-bottom light zindex-10"></div>
    </v-app>
</template>
<script>
import Vue from "vue";
const userAgent = navigator.userAgent || navigator.vendor || window.opera;
const parentProtocol = window.parent.location.protocol || "http";
const parentHost = window.parent.location.host || "localhost";

import RSheader from "@/components/layout/red-sparrow-header";
import RSfooter from "@/components/layout/red-sparrow-footer";
import UserMixin from "@/mixins/user-mixin";

import _ from "lodash";

import "@/fonts/Poppins/index.css";
import { mapState } from "vuex";

//plugins
let App;
// try {
//     App = window.parent.Capacitor.Plugins.App;
// } catch (e) {
//     console.error("(App) Capacitor not found.");
// }

export default {
    name: "App",
    components: { RSheader, RSfooter },
    mixins: [UserMixin],
    meta: {
        title: "Red Sparrow",
    },

    // watch: {
    //     // $route: {
    //     //   handler(newVal) {
    //     //     console.warn("this.$route WATCH:", newVal);
    //     //   },
    //     //   deep: true,
    //     //   // immediate: true,
    //     // },
    //     headerCache(newVal) {
    //         console.warn("Header Cache incremented", newVal);
    //     },
    // },
    data() {
        return {
            moduleImage: {},
            loading: true,
            updateChecked: 0,
            platform: "web",
            fullscreen: false,
            scrolled: false,
            isCreated: false,
            config: {},
        };
    },
    async created() {
        //ADD ORIENTATION EVENT LISTENER TO APP
        window.matchMedia("(orientation: portrait)").addEventListener("change", (e) => {
            const portrait = e.matches;
            // console.log("Orientation Watch Media event listner FIRED!, portrait:", portrait);
            if (portrait) {
                this.$store.commit("generalSet", {
                    key: "orientation",
                    data: "portrait",
                });
            } else {
                this.$store.commit("generalSet", {
                    key: "orientation",
                    data: "landscape",
                });
            }
        });

        var self = this;
        this.isCreated = true;
        if (App) {
            this.platform = window.parent.APP_PLATFORM || "web";

            //Window fullscreen checker
            window.onresize = () => this.setFullscreen();

            setTimeout(function () {
                window.parent.postMessage("READY", `${parentProtocol}//${parentHost}`);
            }, 1500);

            window.addEventListener("message", (event) => {
                if (event.data === "UPDATED_MANIFEST") {
                    console.log("UPDATED_MANIFEST_ENGINE");
                } else if (event.data === "UPDATED_FULL") {
                    console.log("UPDATED_FULL_ENGINE");
                } else if (event.data === "ENGINE_HAS_UPDATE") {
                    console.log("ENGINE_HAS_UPDATE");
                    this.$refs.updateDialog.open(null, `There is a small app update. Would you like to apply this now?`);
                }
            });

            //first remove all app listeners
            if (window.parent.appListeners) {
                window.parent.appListeners.forEach((listener) => {
                    listener.remove();
                });
                window.parent.appListeners = [];
            } else {
                App.removeAllListeners();
            }

            window.parent.appListeners = window.parent.appListeners || [];

            const stateFunction = (state) => {
                // state.isActive contains the active state
                console.log("Internal App state changed.", state.isActive);
                if (state.isActive) this.checkUpdates();
            };

            if (window.parent.APP_ELECTRON_WINDOW) {
                const focusListener = {
                    fn: function () {
                        stateFunction({ isActive: true });
                    },
                    remove: function () {
                        window.parent.APP_ELECTRON_WINDOW.on("focus", this.fn);
                    },
                };
                window.parent.APP_ELECTRON_WINDOW.on("focus", focusListener.fn);
                window.parent.appListeners.push(focusListener);

                const blurListener = {
                    fn: function () {
                        stateFunction({ isActive: false });
                    },
                    remove: function () {
                        window.parent.APP_ELECTRON_WINDOW.on("blur", this.fn);
                    },
                };
                window.parent.APP_ELECTRON_WINDOW.on("blur", blurListener.fn);
                window.parent.appListeners.push(blurListener);
            } else {
                const appStateChange = App.addListener("appStateChange", stateFunction);
                window.parent.appListeners.push(appStateChange);
            }

            // Listen for serious plugin errors
            const pluginError = App.addListener("pluginError", (info) => {
                console.error("There was a serious error with a plugin INTERNAL", info);
            });
            window.parent.appListeners.push(pluginError);

            // async onBackButton() {
            // if (this.$route.name !== 'locked' && await Application.isLocked()) {
            // this.$router.push({ name: 'locked' });
            // } else if (this.$route.name !== 'locked' &&
            // this.$route.name !== 'set-pin') {
            // this.$router.go(-1);
            // }
            // }

            const backButton = App.addListener("backButton", (eventData) => {
                console.log("App back with URL: " + eventData.url);
                self.$router.go(-1);
                // App.exitApp()
            });

            window.parent.appListeners.push(backButton);

            const appUrlOpen = App.addListener("appUrlOpen", (eventData) => {
                // alert('Did launch application from the link!');

                console.log("App opened with URL: " + eventData.url);

                var url = new URL(eventData.url);

                if (url.pathname === "/auth/signup") {
                    self.$router.push({
                        name: "auth.signup",
                    });
                } else if (
                    (url.pathname === "/auth/reset" && url.searchParams.get("token")) ||
                    (url.pathname === "/auth/welcome" && url.searchParams.get("token"))
                ) {
                    self.$router.push({
                        name: "auth.reset",
                        query: { token: url.searchParams.get("token") },
                    });
                }
            });

            window.parent.appListeners.push(appUrlOpen);
        }
    },
    computed: {
        ...mapState(["headerCache", "orientation"]),
        hideHeader() {
            return this.$route.meta.hideHeader && this.orientation == "landscape" && this.$vuetify.breakpoint.smAndDown;
        },
        hideFooter() {
            return this.$route.meta.hideFooter;
        },
        authenticated() {
            //Returns true if we are running as an app or logged in as a user
            var isUser = this.user;
            var isApplication = this.application && this.application.authenticationStyle == "application";

            if (isUser || isApplication) {
                return true;
            }

            //We're not authenticated at all
            return false;
        },
        background() {
            return "";
            // console.log(this.$route.path, "path");
            // if (this.$route?.path?.includes("auth") || this.$route?.path?.includes("account")) {
            //   return "bg-primary";
            // } else if (this.$route?.name == "library" || this.$route?.name == "contact-us") {
            //   return "bg-library";
            // } else if (
            //   this.$route?.name == "schedule" ||
            //   this.$route?.name == "create-schedule" ||
            //   this.$route?.name == "edit-schedule"
            // ) {
            //   return "bg-schedules";
            // } else if (this.$route?.path?.includes("bespoke")) {
            //   return "bg-transitions";
            // } else {
            //   return "";
            // }
        },
    },
    methods: {
        routeMounted() {
            // console.warn("Route mounted?");
            this.loading = false;
        },
        // async checkUpdates() {
        //     const currentTime = new Date().getTime();
        //     const timeDiff = currentTime - this.updateChecked;

        //     if (timeDiff > 86400000) {
        //         window.parent.postMessage("CHECK_UPDATE", `${parentProtocol}//${parentHost}`);
        //     }

        //     await this.$meo.init(this.$meo.user);
        // },
        // confirmUpdate() {
        //     this.updateChecked = new Date().getTime();
        //     this.$refs.updateDialog.close();

        //     window.parent.postMessage("UPDATE_FULL", `${parentProtocol}//${parentHost}`);
        // },
        cancelUpdate() {
            this.updateChecked = new Date().getTime();
        },
        setFullscreen() {
            setTimeout(() => {
                this.fullscreen = window.parent.APP_FULLSCREEN || false;
            }, 100);
        },
        handleScroll(e) {
            if (e.target.scrollTop >= 140) {
                this.scrolled = true;
            } else {
                this.scrolled = false;
            }
        },
        async getProducts() {
            const query = {
                _type: "product",
                status: "active",
                _id: {
                    $in: ["64262004c18abe7229db9f4c", "641a756e82c6a4039eb70811", "641a75c582c6a4039eb7081b"],
                },
            };

            return await this.$fluro.api
                .post("/content/_query", query, {
                    select: "amount currenecy",
                })
                .then(({ data }) => {
                    console.warn("Retrieve products on initial load:", data);
                    Vue.prototype.$app.monthlySubscription = data.find((item) => item._id == "641a75c582c6a4039eb7081b");
                    Vue.prototype.$app.annualSubscription = data.find((item) => item._id == "641a756e82c6a4039eb70811");
                    Vue.prototype.$app.freeTrial = data.find((item) => item._id == "64262004c18abe7229db9f4c");
                    return data;
                })
                .catch((err) => console.log(err));
        },
    },
    async mounted() {
        document.querySelector(".v-main__wrap").addEventListener("scroll", this.handleScroll);
        //  console.log("$fluro:", this.$fluro);
        //  console.log("$content:", this.$fluro.content);

        // this.$router.history.listen((newLocation) => {
        //   console.warn("Listen on load?", newLocation);
        // });

        //Initialise product prototypes for when there is no user (for pricing dialog)
        await this.getProducts();
    },
    destroyed() {
        document.querySelector(".v-main__wrap").removeEventListener("scroll", this.handleScroll);
    },
};
</script>
<style lang="scss">
:root {
    --primary: #ef493f;
    --constraint: 1800px;
}

//Imported index.css file from /fonts/Poppins
//Also need to apply font-family to vuetify text class
html,
body,
.v-application,
.v-application[class*="text-"] {
    font-size: clamp(11px, 1vw, 25px);
    font-family: "Poppins", sans-serif !important;
}

.bg-secondary {
    background: lighten(#bee3db, 10%);
    color: #2a3634;
}

a {
    text-decoration: none;
    color: var(--primary);
}

.v-btn,
.ux-button {
    display: inline-block;
    background: #eee;
    padding: 0.6em 1.3em;
    line-height: 1;
    text-overflow: hidden;
    overflow: hidden;
    white-space: nowrap;
    font-size: 1.4em;
    border-radius: 2em;
    margin: 0 0.5em 0.5em 0;
    background: var(--primary) !important;
    box-shadow: none !important;
    letter-spacing: 0;
    color: #fff !important;
    font-weight: bold;
    cursor: pointer;
    border: 0.1em solid transparent;

    &:hover {
        background: #000;
    }

    &.dark {
        background: #111;

        &:hover {
            background: #000;
        }
    }

    &.v-btn--text {
        background: none !important;
        border: none !important;
        color: inherit !important;
    }

    &.outline {
        background: none !important;
        border-color: inherit !important;
        color: inherit !important;
    }
}

//If apply to '*', breaks mdi icons...
// * {
//   font-family: "Poppins", sans-serif;
// }

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-weight: 800;
    letter-spacing: -0.03em;
    line-height: 1.1;
    margin-bottom: 0.3em;
}

h1,
.h1 {
    font-size: 3em;
}

h2,
.h2 {
    font-size: 2.5em;
}

h3,
.h3 {
    font-size: 2.2em;
}

h4,
.h4 {
    font-size: 1.5em;
}

h5,
.h5 {
    font-size: 1.2em;
}

h6,
.h6 {
    font-size: 1em;
}

.font-xxs {
    font-size: 0.7em;
}

.font-xs {
    font-size: 0.8em;
}

.font-sm {
    font-size: 0.9em;
}

.font-md {
    font-size: 1em;
}

.font-lg {
    font-size: 1.1em;
}

.font-xl {
    font-size: 1.2em;
}

.font-xxl {
    font-size: 1.3em;
}

.font-muted {
    opacity: 0.5;
}

.visible-xs {
    @media (max-width: 768px) {
        display: block !important;
    }
    @media (min-width: 769px) {
        display: none !important;
    }
}

.hidden-xs {
    @media (max-width: 768px) {
        display: none !important;
    }
}

.user-avatar {
    margin: auto;
    padding: 2em 1em;
    text-align: center;
}

.constraint {
    margin: auto;
    max-width: var(--constraint);
    width: 80vw;
    padding: 0 20px;

    @media (max-width: 640px) {
        width: auto;
        overflow: hidden;
    }

    @media (min-width: 769px) {
        &.sm {
            width: 100%;
            max-width: 960px;
        }
    }
}

.section-panel {
    padding: 4% 0;

    .constraint {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4em;
    }

    &.centered {
        text-align: center;
    }

    &.reverse .constraint {
        flex-direction: row-reverse;
    }

    .text {
        flex: 1;
    }

    .image {
        width: 40%;
    }

    &.cover-panel {
        .constraint {
            height: 100vw;
            min-height: 450px;
            max-height: 900px;

            .image {
                width: 50%;
            }

            h1 {
                font-size: 4em;
            }
        }
    }

    &.cta {
        text-align: center;
        background: var(--primary);
        color: #fff;
    }

    @media (max-width: 768px) {
        padding: 2em 0;

        .constraint {
            width: auto;
            overflow: hidden;
            gap: 0;
            flex-direction: column-reverse !important;
        }

        .image {
            margin-bottom: 2em;
        }

        &.cover-panel .constraint {
            height: auto;
            max-height: none;
            min-height: none;

            .image {
                width: 100%;
            }
        }
    }
}

.section-quote {
    text-align: center;
    background: #bee3db;
    color: #2a3634;
    padding: 3em 0;

    .quote {
        font-size: 2em;
        font-weight: bold;
        line-height: 1.1;
        margin-bottom: 0.3em;
    }

    .cite {
        opacity: 0.8;
    }
}

body,
html {
    overscroll-behavior-x: none !important;
    overscroll-behavior-y: none !important;
    overscroll-behavior: none !important;

    * {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    // @media (min-width: 960px) {
    //     width: 100vw;
    //     height: 100vh;
    //     position: relative;
    // }

    // @media (max-width: 960px) {
    //     // overflow: hidden;
    //     min-height: 100vh;
    //     #app {
    //         padding-bottom: calc(64px + env(safe-area-inset-bottom));
    //     }
    // }

    // #app {
    //   max-width:2000px;
    //   margin:auto;
    // }
    //
    //
    .container {
        padding: 0;
    }

    .v-application {
        @media (min-width: 960px) {
            height: 100vh;
            width: 100vw;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .responsive-header {
            font-size: min(max(5vw, 20px), 30px);
        }

        i {
            font-size: 20px;
        }

        // .v-btn {
        //     font-weight: bold;
        //     text-transform: none;
        //     // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
        //     // border-radius: 10px;

        //     &.v-btn--outlined,
        //     &.v-btn--text {
        //         box-shadow: none;
        //     }
        // }

        .v-application--wrap {
            .navigation-container {
                height: 100%;
                overflow: hidden;

                .v-main {
                    height: 100%;
                    overflow: hidden;
                    // background: var(--v-background-base) !important;

                    .v-main__wrap {
                        // overflow-y: scroll;
                        overflow-x: hidden;
                        -webkit-overflow-scrolling: touch;
                    }
                }

                &.desktop {
                    display: flex;
                    align-items: flex-start;

                    .v-main {
                        // background: var(--v-primary-base) !important;
                    }
                }
            }
        }
    }
}

.v-image,
.v-image__image,
.v-responsive__content,
.v-responsive__sizer {
    overflow: hidden !important;
}

.module-section {
    background: #ffffff;
    border-radius: 10px !important;
    padding: 10px;
    position: relative;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1) !important;
    display: flex;
    align-items: center;

    blockquote {
        padding: 15px;
        background: var(--v-primary-base);
        margin-bottom: 16px;
        border-radius: 10px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);

        p {
            font-weight: bold;

            em {
                font-weight: normal;
            }
        }
    }

    p {
        &:last-child {
            margin: 0;
        }
    }
}

.v-dialog__content {
    // z-index: 900 !important;

    .v-dialog {
        background: #ffffff;

        &.v-dialog--fullscreen {
            padding: env(safe-area-inset-top, 20px) env(safe-area-inset-right, 20px) env(safe-area-inset-bottom, 20px)
                env(safe-area-inset-left, 20px);
            background: var(--v-background-base);
        }

        &.v-bottom-sheet {
            border-top-right-radius: 15px !important;
            border-top-left-radius: 15px !important;
            overflow: hidden !important;
            // height: 100%;
            padding-bottom: constant(safe-area-inset-bottom, 20px);
            padding-bottom: env(safe-area-inset-bottom, 20px);
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                // display: flex;
                position: relative;
                width: calc(100% - 20px);
                margin-left: auto;

                &:before {
                    content: "";
                    background: var(--v-primary-base);
                    font-weight: bold;
                    // display: inline-block;
                    display: block;
                    width: 10px;
                    height: 10px;
                    // flex-shrink: 0;
                    // margin-right: 10px;
                    // margin-top: 7px;
                    position: absolute;
                    top: 7px;
                    left: -20px;
                }
            }
        }
    }
}

.muted {
    opacity: 0.7;
}

.muted-1 {
    opacity: 0.5;
}

.muted-2 {
    opacity: 0.3;
}

.pointer-events-none {
    pointer-events: none;
}

.v-bottom-navigation {
    box-sizing: initial !important;
    padding-bottom: env(safe-area-inset-bottom) !important;
}

.scroll-hide {
    /* Hide scrollbar for IE, Edge and Firefox */
    overflow: scroll !important;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    &::-webkit-scrollbar {
        background: transparent;
        /* Chrome/Safari/Webkit */
        width: 0px;
    }
}

.scroll-hide::-webkit-scrollbar {
    display: none;
}

.overflow-auto {
    overflow: auto;
}

.overflow-hidden {
    overflow: hidden;
}

.full-height {
    height: 100%;
}

.min-height-100 {
    min-height: 100%;
}

.full-width {
    width: 100%;
}

.width50 {
    width: 50%;
}

.height50 {
    height: 50%;
}

.p-absolute {
    position: absolute !important;

    &.fit {
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
    }

    &.center {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &.top0 {
        top: 0px;
    }

    &.top5 {
        top: 5px;
    }

    &.top10 {
        top: 10px;
    }

    &.top15 {
        top: 15px;
    }

    &.top20 {
        top: 20px;
    }

    &.top25 {
        top: 25px;
    }

    &.bottom0 {
        bottom: 0px;
    }

    &.bottom5 {
        bottom: 5px;
    }

    &.bottom10 {
        bottom: 10px;
    }

    &.bottom15 {
        bottom: 15px;
    }

    &.bottom20 {
        bottom: 20px;
    }

    &.left5 {
        left: 5px;
    }

    &.left10 {
        left: 10px;
    }

    &.left15 {
        left: 15px;
    }

    &.left20 {
        left: 20px;
    }

    &.right5 {
        right: 5px;
    }

    &.right10 {
        right: 10px;
    }

    &.right15 {
        right: 15px;
    }

    &.right20 {
        right: 20px;
    }
}

.p-relative {
    position: relative !important;
}

.p-fixed {
    position: fixed !important;

    &.top0 {
        top: 0px;
    }

    &.top5 {
        top: 5px;
    }

    &.top10 {
        top: 10px;
    }

    &.top15 {
        top: 15px;
    }

    &.top20 {
        top: 20px;
    }

    &.bottom0 {
        bottom: 0px;
    }

    &.bottom5 {
        bottom: 5px;
    }

    &.bottom10 {
        bottom: 10px;
    }

    &.bottom15 {
        bottom: 15px;
    }

    &.bottom20 {
        bottom: 20px;
    }

    &.left5 {
        left: 5px;
    }

    &.left10 {
        left: 10px;
    }

    &.left15 {
        left: 15px;
    }

    &.left20 {
        left: 20px;
    }

    &.right5 {
        right: 5px;
    }

    &.right10 {
        right: 10px;
    }

    &.right15 {
        right: 15px;
    }

    &.right20 {
        right: 20px;
    }
}

.no-selection {
    -webkit-user-select: none;
    user-select: none;
}

.zindex-1 {
    z-index: 1;
}

.zindex-2 {
    z-index: 2;
}

.zindex-3 {
    z-index: 3;
}

.zindex-4 {
    z-index: 4;
}

.zindex-5 {
    z-index: 5;
}

.zindex-6 {
    z-index: 6;
}

.zindex-7 {
    z-index: 7;
}

.zindex-8 {
    z-index: 8;
}

.zindex-9 {
    z-index: 9;
}

.zindex-10 {
    z-index: 10;
}

.zindex-max {
    z-index: 9999 !important;
}

.opacity-50 {
    opacity: 0.5 !important;
}

.opacity-60 {
    opacity: 0.6 !important;
}

.opacity-70 {
    opacity: 0.7 !important;
}

.opacity-80 {
    opacity: 0.8 !important;
}

.opacity-90 {
    opacity: 0.9 !important;
}

.overflow-ellipsis {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.border-radius-100 {
    border-radius: 100%;
}

.safe-area-padding-top {
    padding-top: constant(safe-area-inset-top) !important;
    padding-top: env(safe-area-inset-top) !important;
    background: rgba(#5f54e0, 0.3);
}

.safe-area-margin-top {
    margin-top: constant(safe-area-inset-top) !important;
    margin-top: env(safe-area-inset-top) !important;
    // background: rgba(#5F54E0, 0.3);
}

.safe-area-padding-bottom {
    padding-bottom: constant(safe-area-inset-bottom) !important;
    padding-bottom: env(safe-area-inset-bottom) !important;
}

th.valign-center {
    span {
        vertical-align: middle;
    }
}

div.v-text-field--outlined fieldset {
    border-color: var(--v-primary-light-base);
    background: #ffffff !important;
}

.v-input {
    .v-input__control {
        .v-input__slot {
            .v-input__prepend-inner {
                z-index: 1;
            }
        }
    }
}

.v-btn:hover:before,
.v-tab:hover:before {
    background-color: transparent;
}

// project utility

.full-device-height {
    height: 100vh !important;
}

.mw-600 {
    max-width: 600px;
}

.mw-100 {
    max-width: 100%;
}

.mw-90 {
    max-width: 90%;
}

.mw-80 {
    max-width: 80%;
}

.bg-blue {
    background-color: #879be2 !important;
}

.bg-primary {
    background-color: #3db2b9 !important;
    color: white;
}

.bg-library {
    background-color: var(--v-library-base);
    color: #444;
}

.bg-schedules {
    background-color: var(--v-schedules-base);
    color: white;
}

.bg-transitions {
    background-color: var(--v-transitions-base);
    color: white;
}

.bg-about-us {
    background-color: rgba(255, 124, 124);
    color: white;
}

.v-tabs-items {
    background-color: inherit !important;
}

.v-tabs-bar .v-tab:not(.v-tab--active) {
    color: inherit !important;
    opacity: 0.5 !important;
}

/*stuff to correct the autofill gloabally */

label.theme--dark + input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    //-webkit-text-fill-color: #fff !important;
    /* inherit only works in Safari */
    -webkit-text-size-adjust: inherit !important;
}

label.theme--light + input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    //-webkit-text-fill-color: white !important;
    /* inherit only works in Safari */
    -webkit-text-size-adjust: inherit !important;
}

/* When dark theme, then make keychain icon white */
label.theme--dark + input::-webkit-credentials-auto-fill-button {
    background-color: #fff !important;
}

/* Hide credentials-auto-fill-button in password inputs, only visible on other inputs */
input[type="password"]::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
}

.title-size {
    font-size: 2.1rem;
}

.subtilte-size {
    font-size: 2.1rem;
}

.body-size {
    font-size: 1.3rem;
}

@media (min-width: 600px) {
    .title-size {
        font-size: 4rem;
    }

    .subtilte-size {
        font-size: 3.2rem;
    }

    .body-size {
        font-size: 1.47rem;
    }
}

.dashed-border {
    border: 3px dashed white;
    border-radius: 15px;
    cursor: pointer;
}

// .title-size{
// }

// .body-text-size{
// }
</style>
