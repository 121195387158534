<template>
    <blockquote class="small-quote" v-if="quote">
    	<div class="wrap">
            <p class="quote">"{{quote.body}}"</p>
            <p class="cite">- {{quote.title}}</p>
        </div>
        </blockquote>
</template>
<script>
const quotes = [];


quotes.push({
    title: `Tamie Smith - parent`,
    body: `‘My child finds the whole Interactive experience of the Red Sparrow Social Stories fun and fantastic, to guide him through a process. The voice-over is very helpful as he’s learning to read.
It’s great to have the library and schedules at hand wherever we go on his iPad or phone.
Well done, Red Sparrow, on an engaging, portable, and sustainable product.’`
})


quotes.push({
    title: `Ash Danes - Foundation teacher Templestowe Heights PS`,
    body: `Implementing Red Sparrow's 360 Explore with our Foundation students enabled them to transition smoothly to their new school. It is a fun way to explore and experience many new things whilst learning about the culture and environment. It has prepared students and parents to start school with confidence and an understanding of what to expect.`
})

quotes.push({
    title: `Christine Gibbs -  Assistant Principal, Eastern Ranges School`,
    body: `At Eastern Ranges School, we've implemented Red Sparrow for Autism’s Interactive Social Stories and 360 Tours to facilitate the transition of students and families to a new school environment. These resources aid in establishing a sense of familiarity with the school surroundings and its various spaces, benefiting both students and their families.`
})


quotes.push({
    title: `Dani Simpson - Tantrum Hair Design`,
    body: `Such a fabulous idea that works. I use Red Sparrow for Autism experiences as a tool in the salon when cutting kids hair and it’s fantastic. ‘Going to the Hairdresser’ has images and interactive sounds so the child is aware of what to expect with each new step. This has made the experience for myself, parents and the child more fun and enjoyable.`
})


quotes.push({
    title: `Suzanne Plant - Assistant Principal, Vermont Secondary College`,
    body: `Using technology-driven tools like Red Sparrow resources can be a valuable addition to the strategies schools use to support students and families during the transition to a new school setting.
It promotes a positive and informed transition experience, setting the stage for a successful and comfortable start to the school year.
`
})



function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}


export default {

    data() {
        return {
            quotes,
        }
    },
    computed:{

    	quote() {
    		return this.quotes[getRandomInt(this.quotes.length)]
    	}
    }
}
</script>

<style lang="scss" scoped>

.small-quote {
    background: #bee3db;
    color: #2A3634;
    padding: 3em 1em;

    .wrap {
    	max-width: 700px;
    	margin: auto;
    }
    .quote {
        font-size: 1.1em;
        line-height: 1.3;
        margin-bottom: 1em;
    }

    .cite {
        opacity: 0.7;
        font-size: 0.9em;
        font-weight: bold;
    }

}


	</style>